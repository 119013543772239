$(document).ready(function() {

    new ShareButton({
        ui: {
            buttonText: ""
        },
        networks: {
            googlePlus: {
                enabled: false
            },
            twitter: {
                enabled: true
            },
            facebook: {
                enabled: true
            },
            pinterest: {
                enabled: true
            },
            reddit: {
                enabled: false
            },
            linkedin: {
                enabled: false
            },
            whatsapp: {
                enabled: true
            },
            email: {
                enabled: false
            }
        }
    });

});