$(document).ready(function() {

	// add extra bottom padding to the main content if there is a sub menu shown
	if ($('#sub-menu').length) {
		$('#content-wrapper').addClass('sub-menu-present');
	}

    // randomise the images (li list-style-image ) on ul items
    var dotClasses = ['dot-1', 'dot-2', 'dot-3', 'dot-4', 'dot-5'];
    dotClasses = shuffleArray(dotClasses);
    $('.random-dot-list li, .other-events-block li').each(function(i) {
        $(this).addClass(dotClasses[i%5]);
    });


	// mobile menu toggle
	$('#mobile-menu-bt').click(function() {
		if (!$('#main-menu').is(':visible')) {
			$('#sub-menu').hide();
		}
		$('#main-menu').slideToggle('fast', function() {
			if ($('#main-menu').css('display') == 'none')  {
				$('#main-menu').css('display', '');
			}
			if (!$('#main-menu').is(':visible')) {
				$('#sub-menu').fadeIn('slow');
			}
		});
		return false;
	});

    $('.to-top-link').each(function(){
        $(this).click(function(){
            $('html,body').animate({ scrollTop: 0 }, 'slow');
            return false;
        });
    });

    $('#restaurant-reservation-form input').blur(function() {
        $(this).addClass('was-focused');
    });


	// FIXME: move to seperate file

	var phswInstances = {};
	$('.image-list').each(function() {

		var listId = $(this).data('id');
		var items = [];


		$(this).find('a').each(function() {
			// if there is a a element in the list item, include the href as image source to the photoswipe
			items.push({
				src : $(this).attr('href'),
				w: $(this).data('img-width'),
				h: $(this).data('img-height'),
				msrc: $(this).find('img').attr('src'),
				title: $(this).find('img').attr('alt'),
			});

		});

		phswInstances[listId] = { items: items};

		$(this).find('a').each(function(i) {

			$(this).click(function () {
				var options = {
					index: i,
					bgOpacity: 0.95,
					galleryUID: listId || 1,
                    closeOnScroll : false,
                    tapToToggleControls: true,
                    barsSize: {top:44, bottom: 44 },
                    clickToCloseNonZoomable: false,
				};

				var gallery = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, items, options);
				gallery.init();

				return false;
			});

		});





	});


    var autoSlideshow = false;
    var loopInterval;
    var nextFromAuto = false;

    function openSlideshow() {

    	if (!slideshowItems) return;

        var options = {
            index: 0,
            bgOpacity: 0.95,
            mainClass : 'slideshow',
            closeOnScroll : false,
            tapToToggleControls: true,
            barsSize: {top:44, bottom: 44 },
            clickToCloseNonZoomable: false,
            galleryUID : 99,
        };


        // Initializes and opens PhotoSwipe
        var slideShowGallery = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, slideshowItems, options);
        slideShowGallery.init();



        clearInterval(loopInterval);

        loopInterval = setInterval(function() {
            if (autoSlideshow) {
                nextFromAuto = true;
                slideShowGallery.next();
            }

        }, 5000);

        // start the slideshow if non-touch device
        if (slideShowGallery.likelyTouchDevice) {

        } else {
            slideShowGallery.options.tapToToggleControls = false;
            slideShowGallery.options.timeToIdle = 99999999;
            slideShowGallery.options.timeToIdleOutside = 99999999;
            autoSlideshow = true;
        }

        initExtraControls(slideShowGallery);

    }

    if(window.location.hash.indexOf('slideshow') !== -1) {
        openSlideshow();
    }

	$('#sub-menu .photos a').click(function(event) {
		openSlideshow();
	});


    function initExtraControls(photoSwipeInstance) {

        var numberOfItems = photoSwipeInstance.items.length;

        function gotoSlide(n) {
            photoSwipeInstance.goTo(n);
        }

        function closeSlide() {
            photoSwipeInstance.close();
        }

        $('#extra-nav-wrapper').html('');

        // add slide navigation
        var ulElement = $('<ul class="nav"></ul>').appendTo('#extra-nav-wrapper');

        for (var i = 0; i < numberOfItems; i++) {

            var isCurrent = photoSwipeInstance.getCurrentIndex() == i;
            var liElement = $('<li><span></span></li>');
            liElement.bind('click', function() {
                gotoSlide($(this).index());
            });
            if (isCurrent) {
                liElement.addClass('current')
            }

            ulElement.append(liElement);

        }

        var controlsContainer = $('<div class="controls-container"></div>').appendTo('#extra-nav-wrapper');


        // add play/pauze
        var autoSlideshowElement = $('<div class="auto-slideshow-controls"></div>').appendTo(controlsContainer);

        if (autoSlideshow) {
            autoSlideshowElement.addClass('is-auto-mode');
        }

        var playElement = $('<div class="play-button"></div>').appendTo(autoSlideshowElement);
        var pauseElement = $('<div class="pause-button"></div>').appendTo(autoSlideshowElement);

        playElement.bind('click', function() {
            autoSlideshow = true;
            $('.auto-slideshow-controls').addClass('is-auto-mode');
        });

        pauseElement.bind('click', function() {
            autoSlideshow = false;
            $('.auto-slideshow-controls').removeClass('is-auto-mode');
        });


        photoSwipeInstance.listen('afterChange', function() {
            $('#extra-nav-wrapper li').each(function() {
                if ($(this).index() == photoSwipeInstance.getCurrentIndex()) {
                    $(this).addClass('current');
                } else {
                    $(this).removeClass('current');
                }

            });

            if (nextFromAuto) {
                nextFromAuto = false;
            } else {
                autoSlideshow = false;
                $('.auto-slideshow-controls').removeClass('is-auto-mode');
            }

        });

        photoSwipeInstance.listen('destroy', function() {
            $('#extra-nav-wrapper').html('');
        });

    }


});

//+ Jonas Raoni Soares Silva
//@ http://jsfromhell.com/array/shuffle [v1.0]
function shuffleArray(o){ //v1.0
    for(var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
    return o;
};



// parse picture index and gallery index from URL (#&pid=1&gid=2)
var photoswipeParseHash = function() {
	var hash = window.location.hash.substring(1),
		params = {};

	if(hash.length < 5) {
		return params;
	}

	var vars = hash.split('&');
	for (var i = 0; i < vars.length; i++) {
		if(!vars[i]) {
			continue;
		}
		var pair = vars[i].split('=');
		if(pair.length < 2) {
			continue;
		}
		params[pair[0]] = pair[1];
	}

	if(params.gid) {
		params.gid = parseInt(params.gid, 10);
	}

	if(!params.hasOwnProperty('pid')) {
		return params;
	}
	params.pid = parseInt(params.pid, 10);
	return params;
};


